import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

function Sidebox({ children, ...rest }) {
	return <S.Container {...rest}>{children}</S.Container>;
}

Sidebox.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Sidebox;
