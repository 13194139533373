import styled from 'styled-components';

import { colors, media, spacing, toRem } from '@styles/Theme';
import { H5 } from '@styles/Typography';

import { Wrapper as LWrapper } from '@components/Layout/styles';

import CSidebox from '@components/Sidebox';

export const MainContent = styled.div`
	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const Wrapper = styled(LWrapper)`
	@media (${media.tablet}) {
		padding-left: ${spacing(8)};
	}
	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding-left: ${spacing(15)};
	}
`;

export const Item = styled.div`
	&:not(:nth-last-child(-n + 2)) {
		margin-bottom: ${spacing(4)};
		padding-bottom: ${spacing(4)};
		border-bottom: 1px solid ${colors.lightGrey};
	}

	> h1 {
		font-size: clamp(3rem, 9vw, 5.2rem);
	}

	.title-drugs {
		margin-top: ${spacing(1)};
	}

	@media (${media.desktop}) {
		> h1 {
			margin-bottom: ${spacing(6)};
		}
	}
`;

export const Sidebox = styled(CSidebox)`
	width: 100%;
	max-width: ${toRem(550)};
	margin: ${spacing(3)} 0;

	p:last-child {
		margin: 0;
	}

	@media (${media.desktop}) {
		position: absolute;
		top: ${spacing(9)};
		right: ${spacing(3)};
		width: ${toRem(320)};
	}
`;

export const SidebarTitle = styled(H5)`
	position: relative;
	padding-left: ${spacing(4)};

	&::after {
		position: absolute;
		top: calc(50% - ${spacing(2)});
		left: 0;
		display: block;
		width: ${spacing(3)};
		height: ${spacing(3)};
		background-image: url(${({ icon }) => icon});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		content: '';
	}
`;

export const Content = styled.div`
	> ul,
	> ol {
		margin: ${spacing(2, 0)};
	}

	ul,
	ol {
		margin-left: ${spacing(5)};
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}
`;

export const ItemDrug = styled.div`
	margin-top: ${spacing(2)};
	margin-bottom: ${spacing(2)};
	padding: ${spacing(3)};
	background: #f4f4f4;

	.title {
		margin-bottom: 2px;
	}
`;

export const LinkDrug = styled.a``;
