import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { H1, H3, H6, P } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';
import Breadcrumb from '@components/Breadcrumb';

import CTA from '@components/CTAS/Appointment';

import SEO from '@components/seo';
import Sidebar from '@components/Sidebars/Main';
import Link from '@components/Link';

import * as S from './_styles';

export const pageQuery = graphql`
	query ClinicConditionBySlug($slug: String!) {
		clinicConditions: allCosmicjsClinicConditions(
			filter: { slug: { eq: $slug } }
		) {
			nodes {
				metadata {
					causes_symptoms
					summary
					treatment
					drugs {
						slug
						title
						metadata {
							commercial_name
							factory
							description
						}
					}
					metas {
						cover {
							imgix_url
						}
						description
						title
					}
				}
				title
				slug
			}
		}
	}
`;

const ClinicConditionsTemplate = ({ data }) => {
	const {
		title: pageTitle,
		slug,
		metadata: { causes_symptoms, summary, treatment, metas, drugs },
	} = data.clinicConditions.nodes[0];

	return (
		<>
			<SEO
				title={`${metas?.title || pageTitle}- Condições Clínicas`}
				description={`${
					metas?.description ||
					`${summary.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 156)}...`
				}`}
				cover={metas?.cover.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb
					crumbs={[
						{ title: 'Condições clínicas', slug: 'condicoes-clinicas' },
						{ title: pageTitle, slug: `condicoes-clinicas/${slug}` },
					]}
				/>
			</Wrapper>
			<Section>
				<S.Wrapper>
					<S.MainContent>
						<S.Item>
							<H1>{pageTitle}</H1>
							<P>{summary}</P>
						</S.Item>
						{causes_symptoms && (
							<S.Item>
								<H3>Causas e sintomas</H3>
								<S.Content
									dangerouslySetInnerHTML={{ __html: causes_symptoms }}
								/>
							</S.Item>
						)}
						{(treatment || drugs) && (
							<S.Item>
								<H3>Tratamento</H3>
								{treatment && (
									<S.Content dangerouslySetInnerHTML={{ __html: treatment }} />
								)}

								{drugs && (
									<>
										<P className="title-drugs">Tratamento farmacológico:</P>
										{drugs.map(
											(
												{
													slug: slugDrug,
													title,
													metadata: { commercial_name, description },
												},
												index
											) => (
												<S.ItemDrug key={`${title}${index}`}>
													<H6 className="title">{commercial_name}</H6>
													<P>{description}</P>
													<Link
														to={`/medicamentos/${slugDrug}`}
														label="Conheça o medicamento"
														withIcon
													/>
												</S.ItemDrug>
											)
										)}
									</>
								)}
							</S.Item>
						)}

						<S.Item>
							<CTA />
						</S.Item>
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>
		</>
	);
};

ClinicConditionsTemplate.propTypes = {
	data: PropTypes.shape({
		clinicConditions: PropTypes.shape({
			nodes: PropTypes.arrayOf(PropTypes.object),
		}),
	}).isRequired,
};

export default ClinicConditionsTemplate;
