import styled from 'styled-components';

import { colors, media, spacing, toRem } from '@styles/Theme';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: ${toRem(550)};
	padding: ${spacing(4)};
	text-align: left;
	background-color: #f4f4f4;
	border-radius: ${spacing(1)};

	h4 {
		margin-bottom: ${spacing(2)};
		color: ${colors.primary};

		&:not(:first-child) {
			margin-top: ${spacing(3)};
		}
	}

	a {
		font-weight: bold;
	}

	a:last-child {
		margin: 0;
	}

	@media (${media.desktop}) {
		max-width: ${toRem(320)};
	}
`;
